import Vue from 'vue';
import Vuex from 'vuex';
import calculater from './calculater-vue';
import store from './calculater-store';
import { mapActions } from 'vuex';

new Vue({
    el: '.calculater-vue',
    store,
    render: r => r( calculater )
    
})
