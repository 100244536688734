var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "head__calculator" }, [
    _c(
      "div",
      { staticClass: "calculator", on: { click: _vm.changeVisionPopupOpen } },
      [
        _c("div", { staticClass: "calculator__image" }),
        _c("div", { staticClass: "calculator__text" }, [
          _vm._v("Расчет стоимости отходов")
        ])
      ]
    ),
    _vm.visionWindow
      ? _c("div", { staticClass: "calculator-popup" }, [
          _c("div", { staticClass: "calculator-window" }),
          _c("div", { staticClass: "window" }, [
            _c("div", {
              staticClass: "window__close",
              on: { click: _vm.changeVisionPopupClose }
            }),
            _c("div", { staticClass: "window__name" }, [
              _vm._v("Расчет стоимости отходов")
            ]),
            _c("div", { staticClass: "window__text" }, [
              _vm._v(" Поиск по названию (ключевому слову) или коду ФККО.")
            ]),
            _c(
              "div",
              { staticClass: "window__form" },
              [
                _c("vue-simple-suggest", {
                  attrs: {
                    list: _vm.simpleSuggestionList,
                    "filter-by-query": true
                  },
                  model: {
                    value: _vm.nameMendeleev,
                    callback: function($$v) {
                      _vm.nameMendeleev = $$v
                    },
                    expression: "nameMendeleev"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "window__button-search",
                    on: { click: _vm.setFetch }
                  },
                  [_vm._v("Искать")]
                )
              ],
              1
            ),
            _vm.visionPrice
              ? _c("div", { staticClass: "widnow__price" }, [
                  _c("div", { staticClass: "price__text" }, [
                    _vm._v("Цена за единицу")
                  ]),
                  _c("div", { staticClass: "price-unit" }, [
                    _c("div", { staticClass: "price-unit__name" }, [
                      _vm._v(_vm._s(_vm.massObj.material))
                    ]),
                    _c("div", { staticClass: "price-unit__cost" }, [
                      _vm._v(_vm._s(_vm.massObj.price) + " руб.")
                    ]),
                    _c("div", { staticClass: "price-unit__value" }, [
                      _vm._v("1 " + _vm._s(_vm.massObj.unit))
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.visionPrice
              ? _c("div", { staticClass: "window__calculation" }, [
                  _c("div", { staticClass: "calculation__box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.count,
                          expression: "count"
                        }
                      ],
                      staticClass: "box__input-quantity",
                      domProps: { value: _vm.count },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.count = $event.target.value
                        }
                      }
                    }),
                    _c("div", { staticClass: "box__text" }, [
                      _vm._v("количество")
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.visionTotal
              ? _c("div", { staticClass: "window__total" }, [
                  _c("div", { staticClass: "total__price-text" }, [
                    _vm._v("Стоимость:")
                  ]),
                  _c("div", { staticClass: "total__price" }, [
                    _vm._v(_vm._s(_vm.massObj.price * _vm.count) + " руб*")
                  ]),
                  _vm._m(0),
                  _c("div", { staticClass: "total__price-text-bottom" }, [
                    _vm._v(
                      "В зависимости от количества отходов, стоимость - договорная."
                    )
                  ])
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "total-string" }, [
      _c("div", { staticClass: "total__star" }, [_vm._v("*")]),
      _c("div", { staticClass: "total__price-text-bottom" }, [
        _vm._v(
          "Цена без стоимости транспортных и погрузочно-разгрузочных услуг"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }