"use strict";

import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        visionWindow: false,
        visionPrice: false,
        visionTotal: false,
        nameMendeleev: '',
        massObj: [],
        count: '',
    },
    mutations: {
        setVisionPopupOpen(state) {
            state.visionWindow = !(state.visionWindow);
        },
        setVisionPopupClose(state) {
            state.visionWindow = false;
            state.visionPrice = false;
            state.visionTotal = false;
        },
        setVisionPrice(state) {
            state.visionPrice = true;
            state.visionTotal = false;
        },
        setName(state, value) {
            state.nameMendeleev = value;
        },
        setMassObj(state, mass) {
            state.massObj = mass;
        },
        setVisionTotal(state) {
            state.visionTotal = true;
        },
        setCount(state, count) {
            state.count = count*1;
            if ( state.count ) {
                state.visionTotal = true;
            }
        }
    },
    getters: {
        getNameMendeleev: state => {
            return _ => {
                return state.nameMendeleev;
            }
        },
        getMassObj: state => {
            return _ => {
                return state.massObj;
            }
        },
        getCount: state => {
            return _ => {
                return state.count;
            }
        },
    },
    actions: {
        async simpleSuggestionList(context) {
            if (context.state.nameMendeleev.length < 2) return;
            let arr = [];
            let response = await fetch(`/api/wastes-calc/search?query=${ context.state.nameMendeleev }`);
            let result = await response.json();
            result.items.forEach(function(item) {
                arr.push(item.material);
            }); 
            return arr;
        },
        async setFetch(context) {
            let response = await fetch(`/api/wastes-calc/search?query=${ context.state.nameMendeleev }`);
            let result = await response.json();
            if (result.items.length == 1) {
                context.commit('setMassObj',result.items[0]);
                context.commit('setVisionPrice');
            }
        },
        changeVisionPopupOpen(context) {
            context.commit('setVisionPopupOpen');
        },
        changeVisionPopupClose(context) {
            context.commit('setVisionPopupClose');
        },
        setPrice(context, name) {
            context.commit('setVisionPrice');
        },
        setTotal(context) {
            context.commit('setVisionTotal');
        },
        setName(context, value) {
            context.commit('setName', value);
        },
        setCount(context, count) {
            context.commit('setCount', count);
        }
    }
});

export default store;
