<script>
import VueSimpleSuggest from 'vue-simple-suggest';
import { mapState, mapGetters, mapActions } from 'vuex';
import 'vue-simple-suggest/lib/polyfills';
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export default {
    methods: {
        ...mapActions([
            'changeVisionPopupOpen', 
            'changeVisionPopupClose', 
            'setPrice',
            'setTotal',
            'setFetch',
            'setName',
            'setCount',
            'simpleSuggestionList'
        ]),
    },
    components: {
        VueSimpleSuggest,
    },
    computed: {
        ...mapState([
            'visionWindow',
            'visionPrice',
            'visionTotal',
            'total',
        ]),
        ...mapGetters([
            'getNameMendeleev',
            'getMassObj',
            'getCount',
            'getList',
        ]),
        debounceSend: function() {
            let DELAY = 2000;
            return debounce(this.simpleSuggestionList, DELAY);
        },
        massObj() {
            return this.getMassObj();
        },
        nameMendeleev: {
            get() { 
                return this.getNameMendeleev();
            },
            set(value) {
                this.setName(value);
            }
        },
        count: {
            get() { 
                return this.getCount();
            },
            set(count) {
                this.setCount(count);
            }
        }
    }

}
</script>
<template lang="pug">
.head__calculator
    .calculator(@click="changeVisionPopupOpen")
        .calculator__image
        .calculator__text Расчет стоимости отходов
    .calculator-popup(v-if="visionWindow")
        .calculator-window
        .window
            .window__close(@click="changeVisionPopupClose")
            .window__name Расчет стоимости отходов
            .window__text  Поиск по названию (ключевому слову) или коду ФККО.
            .window__form
                //input(v-model='nameMendeleev' :list='simpleSuggestionList' :filter-by-query='true').window__input-text
                vue-simple-suggest(v-model='nameMendeleev' :list='simpleSuggestionList' :filter-by-query='true')
                .window__button-search(@click="setFetch") Искать
            .widnow__price(v-if="visionPrice")
                .price__text Цена за единицу
                .price-unit
                    .price-unit__name {{ massObj.material }}
                    .price-unit__cost {{ massObj.price }} руб.
                    .price-unit__value 1 {{massObj.unit}}
            .window__calculation(v-if="visionPrice")
                .calculation__box
                    input(v-model="count").box__input-quantity
                    .box__text количество
            .window__total(v-if="visionTotal")
                .total__price-text Стоимость:
                .total__price {{ massObj.price * count }} руб*
                .total-string
                    .total__star *
                    .total__price-text-bottom Цена без стоимости транспортных и погрузочно-разгрузочных услуг
                .total__price-text-bottom В зависимости от количества отходов, стоимость - договорная.
</template>
<style lang="scss">
    .vue-simple-suggest.designed.focus{
    } 
        .input-wrapper{
            font-size: 16px;
            width: 300px;
            height: 40px;
            margin-right: 20px;
        }
            .default-input{

            } 
        .suggestions{
            left:0;
        } 
            .suggest-item{
                font-size: 16px;
                cursor:pointer;
                padding:5px;
                list-style-type: none;
            }
            .suggest-item:hover {
                background-color:#a8a8a8;
            }
</style>
